$background: #f5f5f5;
$primary: #333333;

$muted: lighten($primary, 20%);

$container-padding: 26px;

$sidebar-width: 260px;
$sidebar-padding: $container-padding;

$sidebar-border-width: 2px;

@mixin styleless-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: 'Crimson Text';
  font-style: normal;
  font-weight: 400;
  src: url(../assets/fonts/CrimsonText-Regular.ttf);
}

@font-face {
  font-family: 'Courier Prime';
  font-style: normal;
  font-weight: 400;
  src: url(../assets/fonts/CourierPrime-Regular.ttf);
}

body {
  background-color: $background;
  color: $primary;
  font-family: 'Crimson Text', serif;
  font-size: 15pt;
  margin: 0;
}

code, tt, pre, kbd, samp {
  font-family: 'Courier Prime', monospace;
  font-size: 11pt;
}

pre {
  margin: 0;
  @media (max-width: 768px) { overflow-x: scroll; }
}

kbd {
  background-color: $primary;
  color: $background;
  padding: 2px 4px;
  border-radius: 4px;
}

figure {
  margin: 0;

  img { max-width: 100%; }
  @media (max-width: 768px) { margin: 1em (-$container-padding); }
  &:last-child { height: 1px; }
  figcaption {
    font-style: italic;
    font-size: 0.85em;
  }
}

figure.w-full {
  width: calc(100vw - 1px);
  margin-left: calc((100vw - 750px + (26px / 2)) / -2);
  text-align: center;
}

figure:first-child,
article + figure {
  margin-top: 1em;
}

dt {
  font-weight: bold;
}

// https://css-tricks.com/snippets/css/prevent-superscripts-and-subscripts-from-affecting-line-height/
sup, sub {
  vertical-align: baseline;
  position: relative;
  top: -0.4em;
}

sub {
  top: 0.4em;
}

.container {
  @media (min-width: 1200px) { width: 170px; }
  @media (min-width: 992px) { width: 970px; }
  @media (min-width: 768px) { width: 750px; }

  padding: 0 $container-padding;
  margin-right: auto;
  margin-left: auto;
  max-width: calc(100vw - ($container-padding * 2))
}

.container.home {
  text-align: center;
  margin: 25vh auto;

  h1 {
    font-size: 2.5em;
  }
  a {
    text-decoration: none;
  }

  .avatar {
    border-radius: 50%;
  }

  nav a {
    padding: 0 0.75em
  }
}

a {
  color: inherit;
}

blockquote {
  border-left: $sidebar-border-width solid darken($background, 50%);
  margin: 1em;
  margin-left: -$sidebar-padding;
  padding-left: $sidebar-padding;
  width: 100%;
}

hr {
  border: none;
  width: 75%;
  margin: 1.5em auto;
  height: 1px;
  background-image: linear-gradient(to right, transparent, $muted, transparent);
}

nav.main {
  padding: 1em 0;
  font-size: 1.2em;
  color: $muted;

  a {
    padding-right: 1.5em;
    text-decoration: none;

    &:hover { color: black; }
  }
}

nav.elsewhere {
  font-size: 0.85em;
  color: $muted;
  a:hover { color: black; }
}

footer.main {
  padding: 1em 0;
  color: $muted;
  font-style: italic;
}

main {
  position: relative;

  aside {
    background-color: darken($background, 10%);
    border-width: 0 $sidebar-border-width;
    padding: .5em $sidebar-padding;

    margin-left: -$sidebar-padding;
    padding-left: $sidebar-padding;
    width: 100%;

    @media (min-width: 1200px + $sidebar-width) {
      position: absolute;
      width: $sidebar-width !important;
      border-right: $sidebar-border-width solid darken($background, 50%);
      border-left: none;

      left: -($sidebar-width  + ($sidebar-padding * 2));
    }
  }
}
div.highlighter-rouge {
  margin: 1em 0;
}

div.highlight {
  border-left: $sidebar-border-width solid darken($background, 50%);
  margin: 0em;
  margin-left: -$sidebar-padding;
  padding-left: $sidebar-padding;
  overflow-x: auto;

  font-size: 9pt;
}

.highlight {
  background-color: transparent;
}

li > div > div.highlight {
  border-left-width: 0;
  margin-left: 0;
  padding-left: 0;
}

.strong {
  font-weight: bold;
}

p {
  line-height: 1.5em;
}

article.post header {
  h1 { margin-bottom: 0.1em; }
}

.post-meta {
  @include styleless-list;
  color: $muted;
  font-size: 0.85em;

  li {
    display: inline;
    &::after {
      content: '·';
      padding: 0 .25em;
    }
    &:last-child::after { content: '' }
  }
}

.post-archives {
  td {
    padding-bottom: .5em;
  }

  .post-date {
    text-align: right;
    padding-right: 1em;
    color: $muted;
    font-size: 0.85em;
    vertical-align: top;
    width: 120px;
    line-height: 2em;
  }
}

.footnotes::before {
  display: block;
  content: "Footnotes:";
  font-size: 1.17em;
  font-weight: bold;
}

.emoji {
  vertical-align: -0.1em;
}
